import React from 'react';
import MainLayout from '../layouts/main';
import { Typography } from '@material-ui/core';
import PageHead from '../components/PageHead';
import styled from '../styled';
import { Link } from 'gatsby';
import { SEO } from '../components/SEO';

const Content = styled('div')`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px auto;
  padding: ${(p) => p.theme.spacing(3)}px;

  a {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export default () => (
  <MainLayout>
    <>
      <SEO
        pathname="/impressum/"
        title="Impressum"
        description="Affilimate's Impressum"
        noIndex
      />
      <PageHead>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          style={{ fontWeight: 900 }}
        >
          Impressum
        </Typography>
      </PageHead>
      <Content>
        <p>
          Adaero Software GmbH
          <br />
          Johanniterstr. 2
          <br />
          10961 Berlin
          <br />
          Germany
          <br />
          <br />
          <a href="mailto:support@affilimate.com">support@affilimate.com</a>
          <br />
        </p>
        <h2>Corporate Information</h2>
        <h3>Registered Office (Sitz der Gesellschaft)</h3>
        <p>
          Adaero Software GmbH
          <br />
          Johanniterstr. 2
          <br />
          10961 Berlin
          <br />
          Germany
        </p>
        <h3>Managing Director (Geschäftsführung)</h3>
        <p>Monica Lent</p>
        <h3>
          Court of Registration and Number (Registergericht und Registernummer)
        </h3>
        <p>Amtsgericht Charlottenburg, HRB 228340 B</p>
        <h3>
          VAT ID (Umsatzsteuer-Identifikationsnummer gemäß §27a
          Umsatzsteuergesetz)
        </h3>
        <p>DE343581319</p>
        <h2>Disclaimer</h2>
        <p>
          This document contains the information required for an Impressum. For
          more information about how your data is used, collected, and stored,
          please read the <Link to="/docs/privacy-policy/">privacy policy</Link>
          .
        </p>
        <h3>Liability for content</h3>
        <p>
          The content of this website was created with great care. We do our
          best to it ensure that it is accurate, but it is possible that it is
          or becomes outdated. Please verify any information provided on this
          website before acting upon it. You may contact us if you find the
          information is inaccurate or outdated so that we can revise it.
        </p>
        <h3>Copyright</h3>
        <p>
          All content on this website is copywritten under German law, unless
          otherwise noted. Reproduction, adaptation, distribution, or any kind
          of exploitation outside the limits of copyright require prior written
          consent of the author. Downloads and copies of these pages are only
          permitted for private, non-commercial use. If you believe that a
          copyright infringement has occurred on this website, please notify us
          immediately.
        </p>
      </Content>
    </>
  </MainLayout>
);
